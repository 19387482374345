@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

body{
  background-color: black;
  color: white;
  font-family: 'Poppins', sans-serif;
}

h1, h2, h3, h4, h5, p{
  margin: 0;
}

p{
  opacity: .8;
  line-height: 1.7rem;
  font-weight: 300;
}