.footer {
    text-align: center;
    padding-top: 100px;
}
.footer_link h4 {
    margin: 10px 0;
    font-size: 22px;
    font-weight: normal;
}
.footer_link a {
    width: 50px;
    height: 50px;
    font-size: 20px;
    border: 1px solid #333;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    color: #fff;
    transition: all 0.3s 0s;
}
.footer_link a:hover {
    border-color: #fff;
}
.footer_link {
    margin: 60px 0;
}
.copyright {
    padding: 2rem 0;
    border-top: 1px solid #222;
    margin-top: 2rem;
}
.footer .title {
    margin-bottom: 40px;
}

.footer .button {
    padding: 12px 36px;
    font-size: 16px;
    background: linear-gradient(45deg, #ff9800, #1976d2 80%);
    text-decoration: none;
    cursor: pointer;
    position: relative;
    z-index: 1;
    color: #fff;
    border-radius: 3px;
}
.footer .button i {
    margin-left: 15px;
    
}


@media (max-width: 768px) {
    .footer{
        padding-top: 40px;
    }
    .footer_link{
        margin: 0;
    }
    .footer .MuiGrid-root{
        margin-top: 0;
    }
    .copyright {
        font-size: 14px;
    }
}
