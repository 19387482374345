.home-section1{padding: 100px 0 0;}
.home-section1 h1{font-size: 70px;font-weight: 100;margin: 0px;}
.home-section1 h1 b{color: #1976d2;background: -webkit-linear-gradient(45deg, #ff9800, #1976d2 80%);background-clip: text;-webkit-background-clip: text;-webkit-text-fill-color: transparent;}

.home-section1 img{
    max-width: 450px;
    width: 100%;
}

.heading {
    margin-bottom: 20px;
    font-size: 12px;
    letter-spacing: 5px;
    text-transform: uppercase;
}
.title{
    font-size: 35px;
    margin-bottom: 70px;
    margin-top: 0px;
    font-weight: 100;
}
.title b {
    background: -webkit-linear-gradient(45deg, #ff9800, #1976d2 80%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.home-section2 {background-color: #232f34;padding: 100px 0;background: linear-gradient(0deg, #232f34, #000000 80%);}

.link-box{display: flex;justify-content: flex-end;align-items: center;}
.link-box a{text-align: right; color: #fff;text-decoration: none;display: flex;align-items: center;justify-content: flex-end;}
.link-box i{width: 50px;height: 50px;border: 2px solid transparent;border-radius: 100%;display: flex;align-items: center;justify-content: center;margin-left: 15px;transition: all 0.3s 0s;font-size: 20px;}
.link-box a:hover i {background-color: #fff;color: #000000;}

.case-study {
    padding: 20px;
    text-align: center;
    margin: 0 10px;
    height: 100%;
    background: linear-gradient(180deg, rgb(35 47 52 / 50%), rgb(0 0 0 / 50%) 80%);
    border-radius: 10px
}
.case-study img {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    height: 250px;
    object-fit: contain;
}
.case-study a {
    color: #fff;
    text-decoration: none;
}
.case-study h3{
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: 500;
}
.case-study a i {
    margin-left: 10px;
}
.case-study a:hover {
    color: #1976d2;
}
.home-section3{
    padding: 100px 0;
    background: linear-gradient(180deg, #232f34, #000000 80%);
    text-align: center;
}
.home-section3 .MuiGrid-container {
    align-items: self-start;
}


.service-box h4 {
    margin-top: 0;
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 1rem;
}
.service-box i {
    font-size: 3rem;
    margin-bottom: 20px;
    display: inline-block;
    background: -webkit-linear-gradient(45deg, #ff9800, #1976d2 80%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.home-section4 .MuiContainer-root {
    background: linear-gradient(45deg, #ff9800, #1976d2 80%);
    padding: 50px;
    border-radius: 20px;
}
.client-logo{
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-self: center;
    height: 100px;
}
.client-logo img{
    max-width: 150px;
    width: 100%;
    max-height: 50px;
    object-fit: contain;
    filter: grayscale(1);
}
.client-logo img:hover{
    filter: grayscale(0);
}



.tech_list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.tech_list li {
    padding: 20px;
    flex: 0 0 80px;
    background: white;
    margin: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    border-radius: 10px;
        transition: all 0.3s ease-in-out;
}
.tech_list li:hover {
    transform: scale(1.1);
}

.tech_list li img {
    width: 100%;
    max-width: 60px;
}
.home-section4{
    text-align: center;
}
.home-section4 .title{
    margin-bottom: 30px;
}
.home-section4 .title b{
    -webkit-background-clip: inherit;
    background: transparent;
    -webkit-text-fill-color: inherit;
}




.contact-form {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    padding: 100px 0;
}
.contact-form .heading {
    text-align: center;
}
.contact-form .title {
    margin-bottom: 30px;
    text-align: center;
}
.contact-form .button {
    margin: 0 auto;
    display: table;
}
.contact-form h1 {
    margin-bottom: 30px;
}
.contact-form label {
    display: block;
    margin-bottom: 10px;
}
.contact-form .form-control {
    width: calc(100% - 2rem);
    border: 0;
    border-radius: 4px;
    background-color: rgb(255 255 255 / 5%);
    padding: 1rem;
    color: #fff;
    font-size: 1.2rem;
    outline: none;
}
.contact-form .form-control:focus {
    background: rgb(255 255 255 / 20%);
}
.contact-form .form-group {
    margin-bottom: 20px;
}

.button {
    padding: 14px 36px;
    font-size: 16px;
    background: linear-gradient(45deg, #ff9800, #1976d2 80%);
    text-decoration: none;
    cursor: pointer;
    position: relative;
    z-index: 1;
    color: #fff;
    border-radius: 3px;
    border: 0;
}

.about-page {
    padding: 100px 0;
}
.about-page img {
    max-width: 100%;
    max-height: 700px;
    width: 100%;
    object-fit: cover;
    object-position: top;
}
.about-page h1 {
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: 100px;
}
.about-page .title {
    margin-bottom: 1rem;
}


.error-page {
    background-image: linear-gradient(180deg, #232f34, #000000 80%);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.error-page i {
    font-size: 5rem;
}
.error-page h1 {
    font-size: 5rem;
}
.error-page h4 {
    font-weight: 100;
    font-size: 1.5rem;
}



@media (max-width: 768px) {
.home-section1 {
    text-align: center;
    padding: 40px 0;
}
.home-section1 h1 {
    font-size: 28px;
}
.home-section1 img{
    margin-top: 40px;
}
.client-logo{
    margin-top: 30px;
}
.client-logo img {
    margin: 0 auto;
}
.home-section2{
    padding: 0;
}
.title {
    font-size: 20px;
    margin-bottom: 40px;
}
.home-section3{
    padding: 40px 0;
}
.heading {
    margin-bottom: 10px;
    letter-spacing: 3px;
}
.home-section4 .MuiContainer-root{
    padding: 40px 0;
}
.tech_list li {
    flex: 0 0 40px;
    height: 40px;
    margin: 5px;
    padding: 20px;
}
.about-page h1{
    margin-bottom: 40px;
    font-size: 1rem;
}
.work-page, .contact-form, .about-page{
    padding: 40px 0;
}
.contact-form .form-control {
    padding: 10px;
    width: calc(100% - 20px);
}
.contact-form .MuiGrid-item:last-of-type {
    padding-top: 0;
}
.addr {
    margin-top: 2rem;
}

}